'use client'
import { useMemo, useState, useRef, isValidElement, useEffect } from 'react'
import type { ChangeEvent, KeyboardEventHandler, ReactNode } from 'react'
import type { UseFormRegister as TypeRegister, FieldError, RegisterOptions } from 'react-hook-form'
import styled, { css } from 'styled-components'
import { mq, vw, parsePlaceHolder, type Col, font18_normal, getP18InputLabel, getP18InputHasContentLabel, rgbAlpha, type AlphaStepNumber, type TypeThemeColors } from '@/styles'
import { Figure } from '../Figure'

type onChangeType = (event: ChangeEvent<HTMLInputElement>) => void

interface iInput {
	readonly type?: string
	readonly name: string
	readonly label?: string
  readonly floatingLabel?: boolean
	placeholder?: string
	defaultValue?: string | number | string[] | null
	readonly required?: boolean | RegisterOptions
	readonly disabled?: boolean
	readonly autoComplete?: string
	readonly readOnly?: boolean
	readonly onChange?: onChangeType
	readonly onKeyUp?: KeyboardEventHandler<HTMLInputElement>
	readonly col?: Col
	readonly register?: TypeRegister<any>
	error?: FieldError | any
	readonly icon?: string | boolean | ReactNode
	readonly className?: string
	readonly placeholderVisible?: boolean
	readonly textColor?: TypeThemeColors
	readonly bgColor?: TypeThemeColors
	readonly autoFocus?: boolean
  readonly leftIcon?: ReactNode
}

interface iLabelProps {
	$hasContent: boolean
	$hidden: boolean
	$hasError?: boolean
	$col: Col
	$placeholderVisible: boolean
	$buttonIcon: boolean
	$textColor?: TypeThemeColors
	$bgColor: TypeThemeColors
	$password?: boolean
  $floatingLabel?: boolean
  $bgColorOpacity?: AlphaStepNumber
}

const hasContentState = (floatingLabel) => css`
  opacity: 0;

  ${floatingLabel && css`
    ${getP18InputHasContentLabel()}
    opacity: 1;
    transform: translateY(-${vw(25, 'mobile')});

    ${mq.greaterThan('nexus7')} {
      transform: translateY(-${vw(30, 'tablet')});
    }

    ${mq.greaterThan('tablet')} {
      transform: translateY(-${vw(30, 'desktop')});
    }

    ${mq.greaterThan('desktop')} {
      transform: translateY(-30px);
    }
  `}
`

const mediaPasswordIcon1 = {
	type: 'svg' as const,
	alt: 'Password eye icon',
	src: '/images/svg/i--eye.svg',
	size: { width: 18, height: 18 }
}
const mediaPasswordIcon2 = {
	type: 'svg' as const,
	alt: 'Password eye icon',
	src: '/images/svg/i--eye.svg',
	size: { width: 18, height: 18 }
}

// aria-invalid https://react-hook-form.com/advanced-usage
const getIcon = (icon, type, setInputType, setIconType, iconType) => {
	if (!icon) return false
	if (typeof icon === 'boolean' || typeof icon === 'string') {
		const mediaIcon = iconType === 'password' ? mediaPasswordIcon2 : mediaPasswordIcon1
		if (type === 'password') {
			const Icon = <button key='icon' type='button' className='btn--toggle-password' onClick={(e) => {
				e.preventDefault()
				setInputType(prevType => prevType === 'password' ? 'text' : 'password')
				setIconType(prevType => prevType === 'password' ? 'text' : 'password')
			}}><Figure media={mediaIcon} fill={false} /></button>
			return [Icon, true]
		} else if (type === 'search') {
			// Search and clear action
		}
	} else if (isValidElement(icon)) {

	}
}


export const noRegister = () => ({ onChange: null, onBlur: null, ref: null, name: null })
// Attributes https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input

export const Input = ({ type = 'text', name = '', defaultValue = '', label, floatingLabel = false, autoComplete, placeholder, placeholderVisible = false, required = false, disabled = false, readOnly = null, onChange = null, col, register = noRegister, icon, className, textColor = 'text', bgColor = 'white', error, autoFocus = false, onKeyUp }: iInput): JSX.Element => {
	const requireProps: RegisterOptions = typeof required === 'boolean' ? { required } : required
	const { onChange: registerOnChange, onBlur: registerOnBlur, name: registerName, ref: registerRef } = register(name, requireProps)
	const ref = useRef<HTMLInputElement | null>(null)

	const [inputType, setInputType] = useState(type)
	const [inputValue, setInputValue] = useState(defaultValue)
	const [iconType, setIconType] = useState('password')
	const isPassword = useMemo(() => type === 'password' && iconType === 'password', [type, iconType])

	const handleChange = e => {
		registerOnChange && registerOnChange(e)
		onChange && onChange(e)
		setInputValue(e?.target?.value)
	}

	const handleBlur = e => {
		registerOnBlur && registerOnBlur(e)
		setInputValue(e?.target?.value)
	}

	const ButtonIcon = useMemo(() => getIcon(icon, type, setInputType, setIconType, iconType), [icon, type, setIconType, iconType])

	useEffect(() => {
		if (error) console.warn(`input ${name} error`, error)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [error])

	useEffect(() => {
		if (autoFocus && ref.current) ref.current.focus()
	}, [autoFocus])

	useEffect(() => {
		if (ref.current && !defaultValue && !inputValue) {
			if (ref.current.value !== '') {
				setInputValue(ref.current.value)
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [defaultValue])

	return (
    <Label $hasContent={!!defaultValue || !!inputValue} $hidden={inputType === 'hidden'} $col={col} $buttonIcon={!!ButtonIcon} $placeholderVisible={placeholderVisible} $textColor={textColor} $bgColor={bgColor} $hasError={!!error} $password={isPassword}{...className && { className }} $floatingLabel={floatingLabel} $bgColorOpacity={.05}>
			<input type={inputType} name={registerName ?? name} disabled={disabled} readOnly={readOnly} placeholder={placeholder ?? label} onChange={handleChange} onBlur={handleBlur} aria-invalid={!!error}
				{...typeof required === 'boolean' && { required }}
				ref={(e) => {
					ref.current = e
					registerRef && registerRef(e)
				}}
				{...autoComplete && { autoComplete }}
				{...defaultValue && { defaultValue }}
				{...(!defaultValue && !registerRef && inputValue && { value: inputValue })}
				{...onKeyUp && { onKeyUp }}
			/>
			{label && <span>{label}</span>}
			{ButtonIcon && ButtonIcon}
			{error?.message && <span role='alert'>{error.message}</span>}
		</Label>
	)
}

const Label = styled.label<iLabelProps>`
  display: flex;
  flex-direction: column;
  margin-bottom: ${vw(10, 'mobile')};
  position: relative;
  width: 100%;

  ${mq.greaterThan('nexus7')} {
    margin-bottom: ${vw(10, 'tablet')};
  }

  ${mq.greaterThan('tablet')} {
    margin-bottom: ${vw(10, 'desktop')};
    width: ${({ $col }) => typeof $col === 'number' ? `${100 * $col / 12}%` : $col};
  }

  ${mq.greaterThan('desktop')} {
    margin-bottom: 10px;
  }

  ${({ $hasError }) => $hasError && css`
    margin-bottom: vw(45, 'mobile')};

    ${mq.greaterThan('nexus7')} {
      margin-bottom: vw(45, 'tablet')};
    }

    ${mq.greaterThan('tablet')} {
      margin-bottom: ${vw(65, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      margin-bottom: 65px;
    }
  `}

  ${({ $hidden }) => $hidden && css`
    opacity: 0;
    pointer-events: none;
    position: absolute;
    user-select: none;
    visibility: hidden;
  `}

  > input {
    background-color: ${({ $bgColor, $bgColorOpacity }) => $bgColorOpacity ? rgbAlpha(`--color-${$bgColor ?? 'white'}`, $bgColorOpacity) : `var(--color-${$bgColor ?? 'white'})`};
    border-bottom: 1px solid;
    border-color: ${({ $hasError }) => $hasError ? '#FF3945' : 'rgba(0, 0, 0, .2)'};
    border-radius: ${vw(20, 'mobile')};
    color: ${({ $hasContent }) => $hasContent ? 'inherit' : rgbAlpha('--color-text', .3)};
    font-size: 15px;
    font-weight: 400;
    height: ${vw(40, 'mobile')};
    letter-spacing: ${({ $password }) => $password ? '5px' : '.6px'};
    padding: ${vw(12.5, 'mobile')} ${vw(15, 'mobile')};
    position: relative;
    transition: 300ms border-color ease-out, 300ms background-color ease-out, 300ms color ease-out;
    width: 100%;

    ${mq.greaterThan('nexus7')} {
      height: ${vw(40, 'tablet')};
      padding: ${vw(12.5, 'tablet')} ${vw(15, 'tablet')};
    }

    ${mq.greaterThan('tablet')} {
      height: ${vw(40, 'desktop')};
      padding: ${vw(10, 'desktop')} ${vw(15, 'desktop')};
    }

    ${mq.greaterThan('desktop-xs')} {
      ${font18_normal(false, 400)}
    }

    ${mq.greaterThan('desktop')} {
      height: 40px;
      padding: 10px 15px;
    }

    &:hover,
    &:focus,
    &:active {
      color: inherit;

      ${({ $bgColorOpacity, $bgColor }) => $bgColorOpacity && css`
        background-color: ${rgbAlpha(`--color-${$bgColor ?? 'white'}`, .15)};
      `}
    }

    &[readonly],
    &[disabled] {
      cursor: auto;
      pointer-events: none;
    }

    &[readonly] {
      opacity: .3;

      + span {
        opacity: .3;
      }
    }

    &[disabled] {
      opacity: .2;

      + span {
        opacity: .2;
      }
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      -webkit-text-fill-color: var(--color-black);
      -webkit-box-shadow: 0 0 0 500px var(--color-white) inset;
      box-shadow: 0 0 0 100px var(--color-white) inset;
      color: inherit;
    }

    &:focus {
      border-color: ${({ $hasError }) => $hasError ? '#FF3945' : 'var(--color-black)'};

      + span {
        ${({ $floatingLabel }) => hasContentState($floatingLabel)}
        color: var(--color-black);
      }
    }

    ${({ $placeholderVisible }) => parsePlaceHolder(`
      opacity: ${$placeholderVisible ? .4 : 0};
      color: ${$placeholderVisible ? 'var(--color-black)' : 'rgba(0, 0, 0 ,0)'};
    `)}
  }

  > span {
    /* FLOATING LABEL */
    &:first-of-type {
      ${({ $placeholderVisible, $floatingLabel }) => $placeholderVisible ? hasContentState($floatingLabel) : getP18InputLabel()}
      border-bottom: 1px solid rgba(0, 0, 0, 0);
      bottom: ${vw(12.5, 'mobile')};
      color: var(--color-text);
      left: ${vw(15, 'mobile')};
      opacity: .3;
      position: absolute;
      transition: 300ms font-size ease, 300ms transform ease, 300ms opacity ease;
      white-space: nowrap;

      ${mq.greaterThan('nexus7')} {
        bottom: ${vw(12.5, 'tablet')};
        left: ${vw(15, 'tablet')};
      }

      ${mq.greaterThan('tablet')} {
        bottom: ${vw(10, 'desktop')};
        left: ${vw(15, 'desktop')};
      }

      ${mq.greaterThan('desktop')} {
        bottom: 10px;
        left: 15px;
      }

      ${({ $hasContent, $floatingLabel }) => $hasContent ? css`${hasContentState($floatingLabel)}` : ''}
    }

    /* ERROR MESSAGE */
    &[role='alert'] {
      color: #FF3945;
      font-size: 12px;
      font-weight: 400;
      pointer-events: none;
      position: absolute;
      top: ${vw(62, 'mobile')};

      ${mq.greaterThan('nexus7')} {
        top: ${vw(62, 'nexus7')};
      }

      ${mq.greaterThan('tablet')} {
        top: ${vw(65, 'desktop')};
      }

      ${mq.greaterThan('desktop')} {
        top: 65px;
      }
    }
  }

  ${({ $buttonIcon }) => $buttonIcon && css`
    > input {
      padding-right: ${vw(40, 'mobile')};

      ${mq.greaterThan('nexus7')} {
        padding-right: ${vw(40, 'tablet')};
      }

      ${mq.greaterThan('tablet')} {
        padding-right: ${vw(40, 'desktop')};
      }

      ${mq.greaterThan('desktop')} {
        padding-right: 40px;
      }
    }

    > button {
      align-items: center;
      background: transparent;
      border-bottom: 1px solid rgba(0, 0, 0, 0);
      display: flex;
      height: 100%;
      opacity: .3;
      position: absolute;
      right: ${vw(15, 'mobile')};
      transition: 250ms opacity linear;
      user-select: none;

      ${mq.greaterThan('nexus7')} {
        right: ${vw(15, 'tablet')};
      }

      ${mq.greaterThan('tablet')} {
        right: ${vw(15, 'desktop')};
      }

      ${mq.greaterThan('desktop')} {
        right: 15px;
      }

      &:hover {
        opacity: 1;
      }

      figure {
        align-items: center;
        display: flex;
        height: 15px;
        justify-content: center;
        width: 15px;
      }

      img {
        height: 100%;
        width: 100%;
      }
    }
  `}
`
