'use client'
import { useState, memo } from 'react'
import type { ChangeEvent } from 'react'
import type { UseFormRegister as TypeRegister, FieldError } from 'react-hook-form'
import styled, { css } from 'styled-components'
import { mq, vw, type Col, getP18InputLabel, getP18InputHasContentLabel, font18_25, font12_15, rgbAlpha, parsePlaceHolder, type TypeThemeColors } from '@/styles'

type onChangeType = (event: ChangeEvent<HTMLInputElement>) => void

interface iTextArea {
  readonly name: string
  readonly label?: string,
  defaultValue?: string
  readonly required?: boolean | { [key:string]: any }
  readonly onChange?: onChangeType
  readonly col?: Col
  readonly register?:TypeRegister<any>
  readonly className?: string
  readonly textColor?: TypeThemeColors
  readonly dropdownTextColor?: TypeThemeColors
  readonly bgColor?: TypeThemeColors
  error?: FieldError | any
  readonly disabled?: boolean
  readonly readOnly?: boolean,
  placeholder?: string
  readonly placeholderVisible?: boolean

}

interface iDivProps {
  $hasContent: boolean
  $textColor?: TypeThemeColors
  $bgColor: TypeThemeColors
  $col: Col,
  $placeholderVisible: boolean,
  $height: number
}

const hasContentState = () => css`
  ${getP18InputHasContentLabel()}
  opacity: 1;
  transform: translateY(0) !important;
`

const noRegister = () => ({ onChange: null, onBlur: null, ref: null, name: null })
const maxheigth = 250
export const TextArea = memo(({ name, label, required = false, onChange = null, col, register = noRegister, className, textColor = 'text', bgColor = 'background', error, disabled, readOnly, placeholder, placeholderVisible = false, defaultValue = '' }:iTextArea):JSX.Element => {
  const requireProps = typeof required === 'boolean' ? { required } : required

  const { onChange: registerOnChange,  name: registerName, ref: registerRef } = register(name, { ...requireProps })
  const [heightTextArea, setHeightTextArea] = useState(60)
  const [textareaValue, setTextareaValue] = useState(defaultValue + '')

  const handleChange = e => {
    registerOnChange && registerOnChange(e)
    onChange && onChange(e)
    setTextareaValue(e.target.value)
    if (e.target.value.trim().length === 0) {
      setHeightTextArea(80)
    }
  }

  const onKeyUp = (e)=> {
    if (e.key === 'Enter') {
      if (heightTextArea <= maxheigth) setHeightTextArea(heightTextArea + 25)
    }
  }

  return (
    <Label $col={col} $textColor={textColor} $bgColor={bgColor} {...className && { className }} $placeholderVisible={placeholderVisible} $height={heightTextArea} $hasContent={textareaValue?.length > 0}>
      <textarea name={registerName ?? name} aria-invalid={!!error} onChange={handleChange} ref={registerRef} disabled={disabled} readOnly={readOnly} placeholder={placeholderVisible ? placeholder : ''} onKeyDown={onKeyUp} defaultValue={defaultValue} />
      {label && <span>{label} · ({textareaValue?.length}/250)</span>}
      {error?.message && <span role='alert'>{error.message}</span>}
    </Label>
  )
})



const Label = styled.label<iDivProps>`
  display: block;
  flex-direction: column;
  height: ${({ $height }) => $height ? `${vw($height + 20, 'mobile')}` : `${vw(105, 'mobile')}`};
  justify-content: flex-end;
  margin-bottom: ${vw(20, 'mobile')};
  min-height: ${vw(105, 'mobile')};
  padding: 0 ${vw(4.25, 'mobile')};
  position: relative;
  width: 100%;

  ${mq.greaterThan('nexus7')} {
    height: ${({ $height }) => $height ? `${vw($height + 20, 'tablet')}` : `${vw(105, 'tablet')}`};
    margin-bottom: ${vw(20, 'tablet')};
    min-height: ${vw(105, 'tablet')};
    padding: 0 ${ vw(4.25, 'tablet') };
  }

  ${mq.greaterThan('tablet')} {
    height: ${({ $height }) => $height ? `${vw($height + 20, 'desktop')}` : `${vw(105, 'desktop')}`};
    margin-bottom: ${vw(30, 'desktop')};
    min-height: ${vw(105, 'desktop')};
    padding: 0 ${vw(12.5, 'desktop')};
    width: ${({ $col }) => typeof $col === 'number' ? `${100 * $col / 12}%` : $col };
  }

  ${mq.greaterThan('desktop')} {
    height: ${({ $height }) => $height ? `${$height + 20}px` : '105px'};
    margin-bottom: 30px;
    min-height: 105px;
    padding: 0 12px;
  }

  ${({ $placeholderVisible, $textColor }) => parsePlaceHolder(`
    color: ${$placeholderVisible ? `var(--color-${$textColor})` : 'rgba(0, 0, 0 ,0)'};
    opacity: ${$placeholderVisible ? .4 : 0};
  `)}

  > span {
    &:first-of-type {
      ${({ $placeholderVisible }) => $placeholderVisible ? hasContentState() : getP18InputLabel()}
      color: ${({ $textColor }) => `var(--color-${$textColor})`};
      opacity: .4;
      position: absolute;
      top: 0;
      transition: 300ms font-size ease, 300ms transform ease, 300ms opacity ease;
      transform: translateY(${vw(25, 'mobile')});
      white-space: nowrap;

      ${mq.greaterThan('tablet')} {
        transform: translateY(${vw(30, 'desktop')});
      }

      ${mq.greaterThan('desktop')} {
        transform: translateY(30px);
      }

      ${({ $hasContent }) => $hasContent ? css`${hasContentState()}` : ''}
    }

    &[role='alert'] {
      ${font12_15(true, 400)}
      bottom: -${vw(52, 'mobile')};
      color: var(--color-input_error);
      left: 0;
      padding: 0 ${vw(4.25, 'mobile')};
      pointer-events: none;
      position: absolute;
      top: 0;

      ${mq.greaterThan('nexus7')} {
        bottom: -${vw(52, 'nexus7')};
        padding: 0 ${vw(4.25, 'tablet')};
      }

      ${mq.greaterThan('tablet')} {
        ${font12_15(false, 400)}
        bottom: -${vw(55, 'desktop')};
        padding: 0 ${vw(12.5, 'desktop')};
      }

      ${mq.greaterThan('desktop')} {
        bottom: -55px;
        padding: 0 12.5px;
      }
    }
  }

  textarea {
    ${font18_25(true, 400)}
    background-color: ${({ $bgColor }) => `var(--color-${$bgColor})`};
    border-radius: 0;
    border-left: none;
    border-right: none;
    border-top: none;
    border-bottom: 1px solid;
    border-color: ${({ $textColor }) => rgbAlpha(`--color-${$textColor}`, .4)};
    display: block;
    height: ${({ $height }) => $height ? `${vw($height, 'mobile')}` : `${vw(80, 'mobile')}`};
    outline: none;
    min-height: ${vw(80, 'mobile')};
    max-height:${vw(250, 'mobile')};
    margin: ${vw(25, 'mobile')} 0  ${vw(12, 'mobile')};
    position: absolute;
    resize: none;
    top: 0;
    width:98%;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;

    ${mq.greaterThan('nexus7')} {
      height: ${({ $height }) => $height ? `${vw($height, 'tablet')}` : `${vw(80, 'tablet')}`};
      min-height: ${vw(80, 'tablet')};
      max-height:${vw(250, 'tablet')};
      margin:  ${vw(25, 'tablet')} 0  ${vw(12, 'tablet')};
    }

    ${mq.greaterThan('tablet')} {
      ${font18_25(false, 400)}
      height: ${({ $height }) => $height ? `${vw($height, 'desktop')}` : `${vw(80, 'desktop')}`};
      min-height: ${vw(80, 'desktop')};
      max-height:${vw(250, 'desktop')};
      margin:  ${vw(25, 'desktop')} 0 0;
    }

    ${mq.greaterThan('desktop')} {
      height: ${({ $height }) => $height ? `${$height}px` : '80px'};
      min-height: 80px;
      max-height: 250px;
      margin: 25px 0 0;
    }

    &:focus {
      border-color: ${rgbAlpha('--color-text', 0.1)};

      + span {
        ${hasContentState()}
        color: var(--color-blue);
        opacity: 1;
      }
    }

    &[readonly],
    &[disabled] {
      cursor: auto;
      pointer-events: none;
    }

    &[readonly] {
      opacity: .3;

      + span {
        opacity: .3;
      }
    }

    &[disabled] {
      opacity: .2;

      + span {
        opacity: .2;
      }
    }

    &[aria-invalid='true'] {
      border-color: var(--color-input_error);
    }
  }
`
