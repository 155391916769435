import { useCallback, useEffect, useRef, useState } from 'react'

export const useDropdown = ({ onChange } = { onChange: null }) => {
  const triggerRef  = useRef(null)
  const dropdownRef = useRef(null)
  const [expanded, setExpanded] = useState(false)

  const toggleExpanded = useCallback(() => {
    setExpanded((state) => {
      return !state})
  }, [])

  const onTriggerFocus = useCallback(() => {
    if(!expanded) setExpanded(true)
  }, [expanded])

  const onMouseClick = useCallback(({ target }) => {

    const triggerClicked = triggerRef.current ? triggerRef.current.contains(target) : null
    const dropdownClicked = dropdownRef.current ? dropdownRef.current.contains(target) : null

    if (!triggerClicked && !dropdownClicked) { // outside click
      setExpanded(false)
    } else if (dropdownClicked) { // dropdown list click
      if (target instanceof HTMLAnchorElement || (target instanceof HTMLButtonElement && target.dataset.dismiss) || target.dataset.value) {
        const value = target.dataset.value
        const text = target.textContent
        onChange && onChange(value, text)
        // NOTE setExpanded shouldBeManaged in the onClick option event, to avoid this mouseCLick to run before and close dropdown before all events have been run.
        // setTimeout(() => {
        //   setExpanded(false)
        // }, 90)
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onEsc = useCallback(e => {
    if (e.keyCode === 27 && expanded === true) setExpanded(false)
  }, [expanded])

  useEffect(() => {
    document[`${expanded ? 'add' : 'remove'}EventListener`]('mousedown', onMouseClick)
    window[`${expanded ? 'add' : 'remove'}EventListener`]('keyup', onEsc)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expanded])

  return [triggerRef, dropdownRef, expanded, toggleExpanded, onTriggerFocus] as const
}

